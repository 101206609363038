<template>
  <div class="orderTest-container">
    <el-form
      ref="form"
      :model="form"
      inline
      label-position="right"
      label-width="90px"
    >
      <el-form-item label="" prop="">
        <el-select
          v-model="form.class_id"
          clearable
          style="width: 160px"
          placeholder="文章板块"
        >
          <el-option
            v-for="(i, idx) in styleSelect"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select
          v-model="form.author_id"
          clearable
          style="width: 140px"
          placeholder="发布人"
        >
          <el-option
            v-for="(i, idx) in adminSelect"
            :key="idx"
            :value="i.id"
            :label="i.username"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select
          v-model="form.status"
          clearable
          style="width: 140px"
          placeholder="状态"
        >
          <el-option
            v-for="(i, idx) in statusSelect"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="-"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button type="primary" @click="handleQuery">查询</el-button>
        <el-button type="primary" @click="handleAdd">添加文章</el-button>
        <el-button type="danger" @click="handleUpdate">更新首页</el-button>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" stripe :data="list">
      <!-- 序号 -->
      <el-table-column align="center" type="index" width="50">
        <template slot="header">序号</template>
      </el-table-column>
      <el-table-column
        v-for="(item, tableIndex) in finallyColumns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        width=""
        align="center"
      >
        <template #default="{ row }">
          <div v-if="item.label == '排序'">
            <el-input
              v-model="row.sort"
              type="number"
              @change="sortChange($event, row)"
            ></el-input>
          </div>

          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop=""
        align="center"
        label="操作"
        fixed="right"
        min-width="160px"
      >
        <template #default="{ $index, row }">
          <el-button
            type="text"
            @click.native.prevent="handleCheck($index, row)"
          >
            查看
          </el-button>

          <el-button
            type="text"
            @click.native.prevent="handleEdit($index, row)"
          >
            编辑
          </el-button>

          <el-button
            v-if="row.status == 1"
            type="text"
            @click.native.prevent="handleDown($index, row)"
          >
            下架
          </el-button>
          <el-button
            v-if="row.status != 1"
            type="text"
            @click.native.prevent="handleDown($index, row)"
          >
            上架
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="bottom">
      <div class="total el-pagination">
        总计:{{ all }}条, 浏览量: {{ view }}
      </div>
      <div class="page">
        <el-pagination
          :current-page="form.pageNo"
          :layout="layout"
          :page-size="form.pageSize"
          :total="total"
          background
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        ></el-pagination>
      </div>
    </div>
    <edit ref="edit" @refresh="fetchData"></edit>
  </div>
</template>
<script>
  import { postAction } from '@/api/Employee'
  import _ from 'lodash'
  import Edit from './components/edit.vue'
  export default {
    name: 'WebSite',
    components: { Edit },
    data() {
      return {
        time: [],
        all: 0,
        view: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
        form: {
          pageSize: 10,
          pageNo: 1,
          class_id: '', //板块类别ID
          author_id: '', //发布人ID
          status: '', //状态 1发布中 2下架
          start_time: '', //开始时间  如2021-9-1
          end_time: '', //结束时间  如2021-9-31
        },
        styleSelect: [
          {
            id: 1,
            name: '行业动态',
          },
          {
            id: 2,
            name: '公司动态',
          },
          {
            id: 3,
            name: '管理技巧',
          },
          {
            id: 4,
            name: '软件教学',
          },
        ],
        list: [],
        total: 0,
        loading: false,
        adminSelect: [],
        statusSelect: [
          {
            id: 1,
            name: '发布中',
          },
          {
            id: 2,
            name: '已下架',
          },
        ],
        columns: [
          {
            order: 1,
            label: '排序',
            prop: '',
            width: '90px',
          },
          {
            order: 2,
            label: '板块',
            prop: 'class_text',
            width: '',
          },
          {
            order: 3,
            label: '标题',
            prop: 'title',
            width: '',
          },
          {
            order: 4,
            label: '发布人',
            prop: 'author_name',
            width: '',
          },
          {
            order: 5,
            label: '发布时间',
            prop: 'create_at',
            width: '',
          },
          {
            order: 6,
            label: '浏览量',
            prop: 'view_num',
            width: '',
          },
          {
            order: 7,
            label: '状态',
            prop: 'status_text',
            width: '',
          },
        ],
        checkList: [
          '排序',
          '板块',
          '标题',
          '发布人',
          '发布时间',
          '浏览量',
          '状态',
        ],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      time(val) {
        if (val) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    created() {},
    mounted() {
      this.getAdminList()
      this.fetchData()
    },
    methods: {
      // 获取超管下拉
      async getAdminList() {
        let { data } = await postAction('/superAdmin/user/index', {
          pageSize: -1,
          pageNo: 1,
        })
        this.adminSelect = data
      },
      async fetchData() {
        this.loading = true
        let { data, totalCount } = await postAction(
          '/superAdmin/article/index',
          this.form
        )
        this.total = totalCount
        this.list = data.list
        this.loading = false
      },
      handleQuery() {
        this.form.pageSize = 10
        this.form.pageNo = 1
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
      sortChange(e, row) {
        console.log('e', e, row)
        postAction('/superAdmin/article/sort', { id: row.id, sort: e }).then(
          (r) => {
            this.$message.success('排序成功')
            this.fetchData()
          }
        )
      },
      // 添加文章
      handleAdd() {
        this.$refs.edit.showType = 3
        this.$refs.edit.showDialog = true
      },
      // 更新首页
      handleUpdate() {},

      // 表格操作
      handleCheck(index, row) {
        this.$refs.edit.showType = 1
        this.$refs.edit.id = row.id

        this.$refs.edit.showDialog = true
      },
      handleEdit(index, row) {
        this.$refs.edit.showType = 2
        this.$refs.edit.id = row.id

        this.$refs.edit.showDialog = true
      },
      handleDown(index, row) {
        postAction('/superAdmin/article/status', {
          id: row.id,
        }).then((r) => {
          this.$message.success('操作成功')
          this.fetchData()
        })
      },
      // handleUp(index, row) {},
    },
  }
</script>
<style lang="scss" scoped>
  .bottom {
    display: flex;
    align-content: center;
    justify-content: space-around;
  }
  .total {
    line-height: 26px;
  }
</style>
