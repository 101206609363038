<template>
  <div class="orderTest-container">
    <div v-show="isEdit" v-html="form.content"></div>
    <div v-show="!isEdit" id="demo1"></div>
  </div>
</template>
<script>
  import E from 'wangeditor'
  import { baseURL } from '@/config'

  export default {
    name: 'Editor',
    components: {},
    props: {
      isEdit: {
        type: Boolean,
        default: () => {
          return false
        },
      },
      con: {
        type: String,
        default: () => {
          return ''
        },
      },
    },
    data() {
      return {
        editor: null,
        form: {
          content: '',
          editorData: '',
        },
      }
    },
    computed: {
      headers() {
        let token = this.$store.getters['user/accessToken']
        return {
          token,
        }
      },
    },
    watch: {
      con(v) {
        if (v) {
          this.$set(this.form, 'editorData', v)
          this.editor.txt.html(v)
        }
      },
    },
    created() {},
    mounted() {
      this.initEditor()
    },
    methods: {
      initEditor() {
        // 富文本
        const editor = new E(`#demo1`)
        // 配置 onchange 回调函数，将数据同步到 vue 中
        editor.config.onchange = (newHtml) => {
          this.form.editorData = newHtml
          this.$emit('change', this.form.editorData)
          // this.$set(this.form, 'editorData', newHtml)
        }
        // 配置 server 接口地址
        let uploadUrl = baseURL + '/baseAdmin/common/upload-image-wangeditor'
        console.log('uploadUrl', uploadUrl)
        editor.config.uploadImgServer = uploadUrl

        // 创建编辑器
        editor.create()
        editor.config.uploadImgMaxSize = 5 * 1024 * 1024 // 5M
        editor.config.uploadFileName = 'files'
        editor.config.uploadImgHeaders = this.headers
        editor.config.uploadImgHooks = {
          // 图片上传并返回了结果，图片插入已成功
          success: function (xhr) {
            console.log('success', xhr)
          },
          // 图片上传并返回了结果，但图片插入时出错了
          fail: function (xhr, editor, resData) {
            console.log('fail', resData)
          },
          // 上传图片出错，一般为 http 请求的错误
          error: function (xhr, editor, resData) {
            console.log('error', xhr, resData)
          },
          // 上传图片超时
          timeout: function (xhr) {
            console.log('timeout')
          },
        }
        this.editor = editor
        // this.content = this.con
        // this.editor.txt.html(this.content)
      },
      clearEditor() {
        console.log('清空editor')
        this.form.content = ''
        this.form.editorData = ''
        this.editor.txt.clear()
      },
    },
  }
</script>
<style lang="scss" scoped></style>
