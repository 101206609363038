<template>
  <el-dialog
    :title="title"
    :visible.sync="showDialog"
    width="1000px"
    top="5vh"
    :close-on-click-modal="false"
    :modal="false"
  >
    <div v-loading="loading" class="orderTest-container">
      <el-form
        ref="form"
        :model="form"
        inline
        label-position="right"
        label-width="90px"
        :rules="rules"
        label-suffix=":"
        :disabled="showType == 1 ? true : false"
      >
        <el-form-item label="发布人" prop="author_name" style="width: 40%">
          <el-input v-model="form.author_name" disabled></el-input>
        </el-form-item>
        <el-form-item label="发布时间" prop="create_at" style="width: 50%">
          <el-input v-model="form.create_at" disabled></el-input>
        </el-form-item>
        <el-form-item label="文章板块" prop="class_id" style="width: 100%">
          <el-select
            v-model="form.class_id"
            clearable
            style="width: 200px"
            placeholder="请选择文章板块"
          >
            <el-option
              v-for="(i, idx) in styleSelect"
              :key="idx"
              :value="i.class_id"
              :label="i.class_name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="文章标题" prop="title">
          <div style="display: flex">
            <el-input
              v-model="form.title"
              style="width: 300px"
              placeholder="文章标题"
            ></el-input>
            <el-checkbox
              v-model="form.is_top"
              true-label="1"
              false-label="0"
              style="margin-left: 10px"
            >
              置顶
            </el-checkbox>
          </div>
        </el-form-item>
        <div></div>
        <el-form-item label="关键词" prop="keyword">
          <el-input
            v-model="form.keyword"
            style="width: 300px"
            placeholder="多个用 ; 隔开"
          ></el-input>
        </el-form-item>
        <div></div>
        <el-form-item label="摘要" prop="summary">
          <el-input
            v-model="form.summary"
            style="width: 300px"
            type="textarea"
          ></el-input>
        </el-form-item>
        <div></div>
        <el-form-item label="缩略图" prop="">
          <upload-img
            v-show="showType != 1"
            ref="uploadImg"
            :is-edit="false"
            :default-img="[form.pic]"
            :max-img="1"
          ></upload-img>
          <div v-if="showType == 1 && form.pic != ''">
            <el-image
              style="width: 200px; height: 200px"
              :src="form.pic"
              :preview-src-list="[form.pic]"
            ></el-image>
          </div>
        </el-form-item>
        <div></div>
        <el-form-item label="" prop="">
          <editor
            v-show="showType != 1"
            ref="editor"
            :con="form.article_text"
          ></editor>
        </el-form-item>
        <el-form-item v-if="showType == 1" label="文章内容" prop="">
          <div v-html="form.article_text"></div>
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button v-if="showType != 1" type="primary" @click="handleSub">
          提交
        </el-button>
        <el-button @click="showDialog = false">关闭</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
  import { postAction } from '@/api/Employee'
  import { mapGetters } from 'vuex'
  import { dateForm } from '@/utils/Time'
  import UploadImg from '@/baseComponents/uploadImg'
  import Editor from '@/baseComponents/editor'
  export default {
    name: 'Edit',
    components: { Editor, UploadImg },
    data() {
      return {
        id: null,
        showDialog: false,
        showType: 1, //1查看，2编辑，3添加
        loading: false,
        rules: {
          class_id: [{ required: true, message: '请输入文章板块' }],
        },
        styleSelect: [
          // {
          //   id: '1',
          //   name: '行业动态',
          // },
          // {
          //   id: '2',
          //   name: '公司动态',
          // },
          // {
          //   id: '3',
          //   name: '管理技巧',
          // },
          // {
          //   id: '4',
          //   name: '软件教学',
          // },
        ],
        form: {
          id: '', // '新闻ID',
          class_id: '1', // '类别ID',
          title: '', // '标题',
          keyword: '', // '关键字',
          summary: '', // '摘要',
          pic: '', // '图片',
          article_text: '', // '内容',
          sort: '', // '排序',
          is_top: '0', // '是否置顶 1是',
          status: '', // '状态 1发布中2已下架',
          view_num: '', // '浏览数',
          author_id: '', // '发布人ID',
          author_name: '', // '发布人名称',
          create_at: '', // '发布时间',
          update_at: '', // '修改时间',
          delete_at: '', // '---',
          class_text: '', // '类别名称',
        },
      }
    },
    computed: {
      title() {
        if (this.showType == 1) {
          return '查看文章'
        } else if (this.showType == 2) {
          return '编辑文章'
        } else {
          return '添加文章'
        }
      },
      // 用户名
      ...mapGetters({
        username: 'user/username',
      }),
    },
    watch: {
      showDialog(v) {
        if (v) {
          if (this.showType != 3) {
            this.fetchData()
          } else {
            // 添加文章事件
            this.form.author_name = this.username
            this.form.create_at = dateForm(new Date())
          }
        } else {
          this.$refs.uploadImg.clearPic()
          this.$refs.editor.clearEditor()
          this.form = {
            id: '', // '新闻ID',
            class_id: '1', // '类别ID',
            title: '', // '标题',
            keyword: '', // '关键字',
            summary: '', // '摘要',
            pic: '', // '图片',
            article_text: '', // '内容',
            sort: '', // '排序',
            is_top: '0', // '是否置顶 1是',
            status: '', // '状态 1发布中2已下架',
            view_num: '', // '浏览数',
            author_id: '', // '发布人ID',
            author_name: '', // '发布人名称',
            create_at: '', // '发布时间',
            update_at: '', // '修改时间',
            delete_at: '', // '---',
            class_text: '', // '类别名称',
          }
        }
      },
    },
    created() {
      this.initSelect()
    },
    mounted() {},
    methods: {
      async initSelect() {
        let res = await postAction('/auth/news/get-class')
        res.data.forEach((i) => {
          i.class_id = i.class_id + ''
        })
        this.styleSelect = res.data
      },
      // 获取详情
      async fetchData() {
        this.loading = true
        let { data } = await postAction('/superAdmin/article/view', {
          id: this.id,
        })
        console.log('data', data)
        this.form = data
        this.loading = false
      },
      handleSub() {
        if (this.showType == 2) {
          // 编辑
          let data = JSON.parse(JSON.stringify(this.form))
          data.id = this.id
          if (this.$refs.uploadImg.fileList.length != 0)
            data.pic = this.$refs.uploadImg.fileList[0].url
          else data.pic = ''
          data.article_text = this.$refs.editor.form.editorData
          console.log('dadadada', data)
          postAction('/superAdmin/article/update', data).then((r) => {
            this.$message.success('添加成功')
            this.$emit('refresh')
          })
        } else if (this.showType == 3) {
          // 添加
          let data = JSON.parse(JSON.stringify(this.form))
          if (this.$refs.uploadImg.fileList.length != 0)
            data.pic = this.$refs.uploadImg.fileList[0].url
          else data.pic = ''
          data.article_text = this.$refs.editor.form.editorData
          console.log('dadadada', data)
          postAction('/superAdmin/article/create', data).then((r) => {
            this.$message.success('添加成功')
            this.$emit('refresh')
          })
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  ::v-deep {
    .el-upload-list--picture-card .el-upload-list__item {
      width: 200px;
      height: 200px;
    }
  }
</style>
