var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.showDialog,
        width: "1000px",
        top: "5vh",
        "close-on-click-modal": false,
        modal: false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "orderTest-container",
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                inline: "",
                "label-position": "right",
                "label-width": "90px",
                rules: _vm.rules,
                "label-suffix": ":",
                disabled: _vm.showType == 1 ? true : false,
              },
            },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "40%" },
                  attrs: { label: "发布人", prop: "author_name" },
                },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.author_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "author_name", $$v)
                      },
                      expression: "form.author_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "50%" },
                  attrs: { label: "发布时间", prop: "create_at" },
                },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.create_at,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "create_at", $$v)
                      },
                      expression: "form.create_at",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%" },
                  attrs: { label: "文章板块", prop: "class_id" },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "200px" },
                      attrs: { clearable: "", placeholder: "请选择文章板块" },
                      model: {
                        value: _vm.form.class_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "class_id", $$v)
                        },
                        expression: "form.class_id",
                      },
                    },
                    _vm._l(_vm.styleSelect, function (i, idx) {
                      return _c("el-option", {
                        key: idx,
                        attrs: { value: i.class_id, label: i.class_name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "文章标题", prop: "title" } },
                [
                  _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "300px" },
                        attrs: { placeholder: "文章标题" },
                        model: {
                          value: _vm.form.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "title", $$v)
                          },
                          expression: "form.title",
                        },
                      }),
                      _c(
                        "el-checkbox",
                        {
                          staticStyle: { "margin-left": "10px" },
                          attrs: { "true-label": "1", "false-label": "0" },
                          model: {
                            value: _vm.form.is_top,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "is_top", $$v)
                            },
                            expression: "form.is_top",
                          },
                        },
                        [_vm._v(" 置顶 ")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c("div"),
              _c(
                "el-form-item",
                { attrs: { label: "关键词", prop: "keyword" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: { placeholder: "多个用 ; 隔开" },
                    model: {
                      value: _vm.form.keyword,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "keyword", $$v)
                      },
                      expression: "form.keyword",
                    },
                  }),
                ],
                1
              ),
              _c("div"),
              _c(
                "el-form-item",
                { attrs: { label: "摘要", prop: "summary" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.form.summary,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "summary", $$v)
                      },
                      expression: "form.summary",
                    },
                  }),
                ],
                1
              ),
              _c("div"),
              _c(
                "el-form-item",
                { attrs: { label: "缩略图", prop: "" } },
                [
                  _c("upload-img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showType != 1,
                        expression: "showType != 1",
                      },
                    ],
                    ref: "uploadImg",
                    attrs: {
                      "is-edit": false,
                      "default-img": [_vm.form.pic],
                      "max-img": 1,
                    },
                  }),
                  _vm.showType == 1 && _vm.form.pic != ""
                    ? _c(
                        "div",
                        [
                          _c("el-image", {
                            staticStyle: { width: "200px", height: "200px" },
                            attrs: {
                              src: _vm.form.pic,
                              "preview-src-list": [_vm.form.pic],
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("div"),
              _c(
                "el-form-item",
                { attrs: { label: "", prop: "" } },
                [
                  _c("editor", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showType != 1,
                        expression: "showType != 1",
                      },
                    ],
                    ref: "editor",
                    attrs: { con: _vm.form.article_text },
                  }),
                ],
                1
              ),
              _vm.showType == 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "文章内容", prop: "" } },
                    [
                      _c("div", {
                        domProps: { innerHTML: _vm._s(_vm.form.article_text) },
                      }),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "dialog-footer" },
            [
              _vm.showType != 1
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleSub },
                    },
                    [_vm._v(" 提交 ")]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showDialog = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }