var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "orderTest-container" }, [
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isEdit,
          expression: "isEdit",
        },
      ],
      domProps: { innerHTML: _vm._s(_vm.form.content) },
    }),
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.isEdit,
          expression: "!isEdit",
        },
      ],
      attrs: { id: "demo1" },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }